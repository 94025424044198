<template>
  <div class="flex md6 xs12 xl4">
    <vac-card
      :title="$t('Retailers Status')"
      class="chart-widget">
      <ExcelPreviewModal
        v-if="isPreview"
        :data="preparedData"
        header="RETAILERS STATUS"
        @disable="togglePreview"
        @exportToExcel="exportToExcel"
      />

      <Actions
        show-percentage-switcher
        :active-switcher-button="activeSwitcherButton"
        :export-to-excel="exportToExcel"
        is-preview
        @togglePreview="togglePreview"
        @toggle-percentage="onToggle($event)"
      />

      <!--      <va-chart :data="data"-->
      <!--                :options="options"-->
      <!--                type="vertical-bar"/>-->
      <Bar
        v-if="!loading"
        :chart-id="'bar-chart'"
        :dataset-id-key="'label'"
        :chart-data="data"
        :css-classes="'bar export'"
        :chart-options="chartOptions"
      />
    </vac-card>
  </div>
</template>

<script>

import VaChart from "../statistics-template/charts/va-charts/VaChart";
import Actions from "../actions/Actions";
import HelperExcel from "../../services/Helpers/HelperExcel";
import ExcelPreviewModal from "@/components/actions/ExcelPreviewModal";
import {Bar} from 'vue-chartjs/legacy'

import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

export default {
  name: "RetailerStatuses",
  components: {
    Actions,
    ExcelPreviewModal,
    Bar
  },
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      options: {
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
            type: 'linear',
          }],
        },
      },
      isPreview: false,
      sum: {},
      activeSwitcherButton: 1,
      loading: false
    };
  },
  async created() {
    this.data.labels.forEach((l, indexLabel) => {

      this.data.datasets.forEach(ds => {
        if (this.sum[l]) {
          this.sum[l] += ds.data[indexLabel]
        } else {
          this.sum[l] = ds.data[indexLabel]
        }
      })
    })
  },
  computed: {
    chartOptions() {
      return {
        scales: {
          x: {
            stacked: true,
            gridLines: {
              display: false,
            },
          },
          y: {
            stacked: true,
            min: 0,
            // max: this.activeSwitcherButton === 1 ? this.sum[this.data.labels[0]] : 100,
            max: this.sum[this.data.labels[0]],
            ticks: {
              beginAtZero: true,
              callback: (value) => {
                if (this.activeSwitcherButton === 1) {
                  return value
                } else {
                  return (value * 100 / this.sum[this.data.labels[0]]).toFixed(0) + "%"
                }
              }
            },
            type: 'linear',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'x',
        plugins: {
          tooltip: {
            mode: 'index',
            callbacks: {
              label: (ttItem) => {
                if (this.activeSwitcherButton === 1) {
                  return `${ttItem.dataset.label}: ${ttItem.raw}`;
                } else {
                  return `${ttItem.dataset.label}: ${(ttItem.raw * 100 / this.sum[ttItem.label]).toFixed(2)}%`;
                }
              }
            }
          },
          legend: {
            position: 'bottom',
            labels: {
              padding: 20,
              usePointStyle: true,
              pointStyle: 'circle'
            }
          },
        }
      };
    },
    preparedData() {
      if (this.activeSwitcherButton === 2) {
        let {labels, datasets} = JSON.parse(JSON.stringify(this.data));

        const returnObj = {}
        returnObj.labels = [...labels]
        returnObj.labels.unshift("Status")
        returnObj.maxLength = []
        let sum = 0
        datasets.forEach(ds => {
          sum += ds.data[0]
        })
        for (let key = 0; key < returnObj.labels.length; key++) {
          returnObj.maxLength[key] = returnObj.labels[key].length;
        }

        returnObj.datasets = datasets.map(data => {
          returnObj.maxLength[0] = Math.max(returnObj.maxLength[0], String(data.label).length)
          returnObj.maxLength[1] = Math.max(returnObj.maxLength[1], String(data.data.reduce((a, b) => (a + b))).length)
          for (let key = 0; key < data.data.length; key++) {
            returnObj.maxLength[key + 2] = Math.max(returnObj.maxLength[key + 2], String(data.data[key]).length)
          }
          data.data = data.data.map(d => {
            return `${(d * 100 / sum).toFixed(2)}%`
          })
          return {
            data: [data.label, ...data.data]
          }
        })
        let sums = ['Total']

        labels.forEach(l => {
          sums.push('100%')
        })
        returnObj.datasets.push({
          data: sums
        })


        return returnObj;
      } else {
        const {labels, datasets} = this.data;

        const returnObj = {}
        returnObj.labels = [...labels]
        returnObj.labels.unshift("Status", "Total")
        returnObj.maxLength = []

        for (let key = 0; key < returnObj.labels.length; key++) {
          returnObj.maxLength[key] = returnObj.labels[key].length;
        }


        returnObj.datasets = datasets.map(data => {
          returnObj.maxLength[0] = Math.max(returnObj.maxLength[0], String(data.label).length)
          returnObj.maxLength[1] = Math.max(returnObj.maxLength[1], String(data.data.reduce((a, b) => (a + b))).length)
          for (let key = 0; key < data.data.length; key++) {
            returnObj.maxLength[key + 2] = Math.max(returnObj.maxLength[key + 2], String(data.data[key]).length)
          }

          return {
            data: [data.label, data.data.reduce((a, b) => (a + b)), ...data.data]
          }
        })

        return returnObj;
      }
    }
  },
  methods: {
    exportToExcel() {
      const customData = [];
      const customLabels = ["Retailer", "Often", "Last week", "Rarely", "No visits", "No map"];
      let {labels, datasets} = JSON.parse(JSON.stringify(this.data));
      let sum = 0;
      datasets.forEach(ds => {
        sum += ds.data[0]
      })
      datasets.forEach(chart => {
        const {label, data} = chart;
        data.forEach((info, index) => {
          const retailer = labels[index];
          const findIndex = customData.findIndex(found => found["Retailer"] === retailer);

          if (this.activeSwitcherButton === 2) {
            info = `${(info * 100 / sum).toFixed(2)}%`
          }
          if (findIndex !== -1) {
            customData[findIndex][label] = info;
          } else {
            const obj = {
              "Retailer": retailer,
              "Often": 0,
              "Last week": 0,
              "Rarely": 0,
              "No visits": 0,
              "No map": 0,
            };

            obj[label] = info || 0;

            customData.push(obj);
          }
        });
      });
      customData.push({
        "Retailer": 'Total',
        "Often": this.activeSwitcherButton === 2 ? '100%' : sum,
        "Last week": this.activeSwitcherButton === 2 ? '100%' : sum,
        "Rarely": this.activeSwitcherButton === 2 ? '100%' : sum,
        "No visits": this.activeSwitcherButton === 2 ? '100%' : sum,
        "No map": this.activeSwitcherButton === 2 ? '100%' : sum,
      })

      HelperExcel.exportToExcel({}, "Retailers Status", customLabels, customData);
    },

    togglePreview() {
      this.isPreview = !this.isPreview;
    },
    onToggle(val) {
      this.loading = true
      this.activeSwitcherButton = val
      setTimeout(() => {
        this.$nextTick(() => {
          this.loading = false
        })
      }, 100)
    }
  },
};
</script>

<style scoped>
.bar {
  height: 471px;
}
</style>
